/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.container-404 {
  position: fixed;
  text-align: center;
  width: 100%; 
  height: 100%; 
  top: 0; 
  left: 0; 
}

.img-full-screen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.top-center {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.h1-custom {
  font-size: 144px;
  font-weight: 100;
  color: white;
  font-family: 'Trebuchet MS', 'Roboto', sans-serif;
}

.h2-custom {
  color: white;
  font-family: 'Trebuchet MS', 'Roboto', sans-serif;
}

.h3-custom {
  font-weight: 700;
  font-size: 16px;
  color: white;
  font-family: 'Trebuchet MS', 'Roboto', sans-serif;
}

.link-bolder {
  font-weight: 900 !important;
}

.fw-semibold-2 {
  font-weight: 510 !important;
}

.icon-best {
  background-color: #73DE3A1F;
  color: #71DD37;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-worst {
  background-color: #FF3A191F;
  color: #FF3E1D;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-row {
  margin-right: 0px;
  margin-left: 0px;
}

.mb-2-2 {
  margin-bottom: 0.7rem !important;
}

.icon-calf {
  background-color: #73DE3A1F;
  color: #71DD37;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-calf {
  background-color: #8080801F;
  color: #808080;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-openHeifer {
  background-color: #8381FF1F;
  color: #8381FF;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-insemHeifer {
  background-color: #90FF001F;
  color: #90FF00;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-uncalved {
  background-color: #57FFFF1F;
  color: #57FFFF;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-involution {
  background-color: #F9B9A11F;
  color: #F9B9A1;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-open {
  background-color: #7E0CFF1F;
  color: #7E0CFF;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-insem {
  background-color: #25803D1F;
  color: #25803D;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-incalf {
  background-color: #3BA7FF1F;
  color: #3BA7FF;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-barren {
  background-color: #F5009D1F;
  color: #F5009D;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-dryoff {
  background-color: #A282001F;
  color: #A28200;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-cull {
  background-color: #F9BF001F;
  color: #F9BF00;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-analytics {
  background-color: #E7E7ff;
  color: #696CFF;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-forecast {
  background-color: #D7F5FC;
  color: #03C3EC;
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-base {
  font-size: 20px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-3-1 {
  margin-top: 1.1rem !important;
} 

.mb-3-2 {
  margin-bottom: 1.25rem !important;
} 

.display-inline-block-add {
  display: inline-block;
}

.login-center {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-card {
  width: 400px;
  height: auto;
  padding: 30px;
}

.milk-growth-center {
  display: flex;
  justify-content: center;
}